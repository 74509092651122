<template>
  <div>
    <NavBar :title="'活动数据'"></NavBar>
    <div class="content">
      <div class="items">
        <div class="item">
          <div>
            <img
              src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220613_111332_62a6ab5cca9f0.png"
              alt="扫码总人数"
            />
            <h3>{{ info.scan_code_user }}</h3>
            <p>扫码总人数</p>
          </div>
          <div>
            <img
              src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220613_112400_62a6add02072b.png"
              alt="已核销用户数量"
            />
            <h3>{{ info.write_off_user }}</h3>
            <p>已核销用户数量</p>
          </div>
        </div>
        <div class="item">
          <div>
            <img
              src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220613_112420_62a6ade4ccfb4.png"
              alt="已核销优惠券数量"
            />
            <h3>{{ info.write_off_num }}</h3>
            <p>已核销优惠券数量</p>
          </div>
          <div>
            <img
              src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220613_112444_62a6adfcb320e.png"
              alt="未核销优惠券数量"
            />
            <h3>{{ info.write_on_num }}</h3>
            <p>未核销优惠券数量</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ActivityData',
  data() {
    return {
      info: {},
    }
  },
  created() {
    if (this.$state.activity.id !== undefined && this.$state.activity.id) {
      this.$http('/merchant/statisticsDetails?id=' + this.$state.activity.id)
        .then(({ data }) => {
          this.info = data
        })
        .catch(() => {
          this.finished = true
          this.loading = false
          this.refreshing = false
        })
    } else {
      this.$notify('请重试.')
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  height: 100vh;
  padding: 0 32px;

  background: #f9f9f9;
  .item {
    display: flex;
    // padding: 16px 32px;
    > div:nth-child(1) {
      margin-right: 16px;
    }
    > div {
      margin-top: 16px;
      width: 50%;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      // margin: 16px;
      padding: 16px 0 16px 16px;
      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
      h3 {
        font-size: 36px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        margin: 0;
        padding: 8px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 24px;
        margin: 0;
      }
    }
  }
}
</style>
